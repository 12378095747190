import { Component, OnInit ,Input} from '@angular/core';
import { PricingAttrType } from '../../util/pricingattrtype';
import { Utils } from '../../util/utils';
import {Message, MessageTypes} from './../../models/message';

@Component({
  selector: 'app-broker-fi-datatable',
  templateUrl: './broker-fi-datatable.component.html',
  styleUrls: ['./broker-fi-datatable.component.css']
})
export class BrokerFIDatatableComponent implements OnInit {
   
  status:string;
  cusip:string;
  brokerSecId:string;
  brokerSecIdOnLoad:string;
  securityName:string;
  dealNotional:string;
  mktValue:string;
  price:number;
  priceOnLoad:number;
  yesterdayUnitizedPrice:string;
  priceDiff:string;
  pricingCurrency:string;
  assetCurrency:string;
  securityType:string;
  maturityDate:string;
  dailyMonthly:string;
  updatedValues = new Map<PricingAttrType, Object>();
  updateFlag:boolean = false;
  brokerSecurityId:string;
  utils = new Utils();
  validationsErrors = [];
  @Input() fiDataArray:any[];
  @Input() fiAllData:any[];

  bkrSecIdFlag:boolean = false;
  bkrPriceFlag:boolean = false;
  
  //commenting other attributes for now since source type is fixed
  // secIdAttr : string = "secIdAttr";
  // priceIdAttr : string = "priceIdAttr";
  // abPrcStatus:string;
  // auditBrokerPrice:number;
  // auditBrokerStatus:string;
  // auditBrokerSecurityId:number;
  // brokerMktValue:number;
  // brokerPriceComments:string;
  // brokerUndlSecPrc:number;
  // brokerVolatility:string;
  // brokerDelta:number;
  // vendorPricingStatusType:string;
  // vendorPriceStatus:string;
  // vendorPrice:number;
  // vendorPriceComments:string;
  // vendorSecurityId:number;
  // secBrokerPricingStatusType:string;
  // secBrokerPriceStatus:string;
  // secBrokerPrice:number;
  // secBrokerMktValue:number;
  // secBrokerPriceComments:string;
  // secBrokerSecurityId:number;
  // secBrokerUndlSecPrc:number;
  // secBrokerVolatility:string;
  // secBrokerDelta:number;
  // secVndPricingStatusType:string;
  // secVndPriceStatus:string;
  // secVndrPrice:number;
  // secVndPriceComments:string;
  // secVndSecurityId:number;
  // middleOffPVComments:string;
  // middleOffSBComments:string;
  // middleOffSVComments:string;
  // dV01:string;
  // fundingLevel:string;
  // parSpread:number;
  // exoticOptionValue:string;
  // theoreticalPrice:number;
  // threshold:number;
  // prevDayAccountingPrice:number;
  constructor() { 
  }

  ngOnInit(): void {  
  }

  syncBVdata(columnName,FIObj,columnVal){
    var value = columnVal.toString().replace(/\u200B/g, '');
    value  = value.trim();
    let index = this.fiAllData.indexOf(FIObj);
    
    if(columnName === 'BROKER_SEC_ID'){
    if(FIObj.brokerSecId != value){
      let pricingattrtype = this.utils.getSourceSecIdAttrBySourceType('PRIMARY_BROKER');
      if(FIObj.brokerSecIdOnLoad == value){
        FIObj.brokerSecId = FIObj.brokerSecIdOnLoad;
        if(FIObj.updatedValues.has(PricingAttrType[pricingattrtype])){
          FIObj.updatedValues.delete(PricingAttrType[pricingattrtype]);
        }
        FIObj.bkrSecIdFlag = false;
      }
      else{
      FIObj.brokerSecId = value;
      //Commenting source type since its fixed for now change according to requirement
      //let sourceType = this.utils.getBrokerSourceTypeDRV('srcId','srcId',null);
     
      FIObj.updatedValues.set(PricingAttrType[pricingattrtype],FIObj.brokerSecId);
      FIObj.bkrSecIdFlag = true;
      //this.fiAllData[index] = FIObj;
      }
    }
    }
    if(columnName === 'BROKER_PRICE'){
      this.removeValidationError(FIObj);
      let zeroFlag = (FIObj.price === 0 && columnVal == '');
      if(zeroFlag || FIObj.price != value){
        let pricingattrtype = this.utils.getSourcePriceAttrBySourceType('PRIMARY_BROKER');
        if(!zeroFlag && FIObj.priceOnLoad == value){
          FIObj.price = FIObj.priceOnLoad;
          if(FIObj.updatedValues.has(PricingAttrType[pricingattrtype])){
            FIObj.updatedValues.delete(PricingAttrType[pricingattrtype]);
          }
          FIObj.bkrPriceFlag = false;
        }
        else{
          if(columnVal == ''){
            FIObj.price = null;
            FIObj.updatedValues.set(PricingAttrType[pricingattrtype],FIObj.price);
            FIObj.bkrPriceFlag = true;
          } 
          else{
        let formattedNum = this.utils.getFormatedColumn(value);
      if(formattedNum != null){
        FIObj.price = parseFloat(this.utils.getFormatedColumn(value));        
        FIObj.updatedValues.set(PricingAttrType[pricingattrtype],FIObj.price);        
        FIObj.bkrPriceFlag = true;
        }
        else{
          FIObj.validationsErrors.push(new Message(FIObj.cusip  ,'Invalid format of Broker Price.Required format: -9999999999999.99999999.',null,Utils.getCurrentTimeEST(),MessageTypes.ERROR));
        }
      }
      }
      }
      }

      if(FIObj.bkrSecIdFlag || FIObj.bkrPriceFlag){
        FIObj.updateFlag = true;
      }
      else{
        FIObj.updateFlag = false;
      }
      this.fiAllData[index] = FIObj;
  }

  removeValidationError(FIObj){
  if(FIObj.validationsErrors.length > 0){
    let msgObjindex = FIObj.validationsErrors.indexOf(this.utils.getMessageObj(FIObj.validationsErrors).get(FIObj.cusip));
    if (msgObjindex > -1) {
      FIObj.validationsErrors.splice(msgObjindex, 1);
    }
  }
}
 

  isEditable(brokerStatus){
    switch (brokerStatus) {
      
      case 'Pending':
        return false;
      case 'Priced':
          return true;
      case 'Rejected':
        return false;
      case 'Accepted':
        return true;
  }
  }

  getpriceDiff(item:any){
    let priceDiff:number = 0;
    if(item.brokerStatus!=='R' && item.prevDaySourcePrice > 0){
     priceDiff =  (100 * (item.brokerPrice - item.prevDaySourcePrice) / item.prevDaySourcePrice);
    }
 return priceDiff + "";
  }
 
 
 
  getStatus(sourceType:string){
   switch(sourceType){
     case "AUDIT_BROKER":
       return "auditBrokerStatus";
     case "PRIMARY_BROKER":
       return "brokerStatus";
     }
  }

  
  scrollHeader() {    
    let mainTbl = document.getElementById("tableDiv");    
    document.getElementById("header").scrollLeft = mainTbl.scrollLeft; 
  }

  getAttribute(item: any,attr:string) {
   let obj = null;
   let productAttr = null;
   if(attr == 'secIdAttr'){
     productAttr = this.utils.getSourceSecIdAttrBySourceType('PRIMARY_BROKER');
     
   }else if(attr == 'priceIdAttr'){
     productAttr = this.utils.getSourcePriceAttrBySourceType('PRIMARY_BROKER');
     
   }
   //console.log("productAttr--"+ PricingAttrType[productAttr]+"----" + item.brokerSecurityId);
     switch (PricingAttrType[productAttr]) {
      case "PB_SEC_ID":
         obj = item.brokerSecurityId;
         break;
      case "PB_PRICE":
        switch (item.brokerPriceStatus) {
        case "R":
          obj = null;
          break;
        default:
          obj = item.brokerPrice;
          break;
        }
        break;
      //commenting other cases for now since source type is fixed
      //  case "AB_PRICE":
      //    switch (item.abPrcStatus) {
      //    case "R":
      //      obj = null;
      //      break;
      //    default:
      //      obj = item.auditBrokerPrice;
      //      break;
      //    }
      //    break;
      //  case "AB_STATUS":
      //    obj = item.auditBrokerStatus;
      //    break;
      //  case "AB_SEC_ID":
      //    obj = item.auditBrokerSecurityId;
      //    break;
      //  case "PB_STATUS":
      //    obj = item.brokerPriceStatus;
      //    break;
      //  case "PB_MKT_VAL":
      //    obj = item.brokerMktValue;
      //    break;
      //  case "PB_COMMENTS":
      //    obj = item.brokerPriceComments;
      //    break;
      //  case "PB_UNDL_SEC_PRICE":
      //    obj = item.brokerUndlSecPrc;
      //    break;
      //  case "PB_VOLATILITY":
      //    obj = item.brokerVolatility;
      //    break;
      //  case "PB_DELTA":
      //    obj = item.brokerDelta;
      //    break;
      //  case "PV_STATUS":
      //    obj = item.vendorPricingStatusType;
      //    break;
      //  case "PV_PRICE":
        
      //    switch (item.vendorPriceStatus) {
      //    case "R":
      //      obj = null;
      //      break;
      //    default:
      //      obj = item.vendorPrice;
      //      break;
      //    }
      //    break;
      //  case "PV_COMMENTS":
      //    obj = item.vendorPriceComments;
      //    break;
      //  case "PV_SEC_ID":
      //    obj = item.vendorSecurityId;
      //    break;
      //  case "SB_STATUS":
      //    obj = item.secBrokerPricingStatusType;
      //    break;
      //  case "SB_PRICE":
        
      //    switch (item.secBrokerPriceStatus) {
      //    case "R":
      //      obj = null;
 
 
      //      break;
      //    default:
      //      obj = item.secBrokerPrice;
      //      break;
      //    }
      //    break;
      //  case "SB_MKT_VAL":
      //    obj = item.secBrokerMktValue
      //    break;
      //  case "SB_COMMENTS":
      //    obj = item.secBrokerPriceComments;
      //    break;
      //  case "SB_SEC_ID":
      //    obj = item.secBrokerSecurityId;
      //    break;
      //  case "SB_UNDL_SEC_PRICE":
      //    obj = item.secBrokerUndlSecPrc;
      //    break;
      //  case "SB_VOLATILITY":
      //    obj = item.secBrokerVolatility;
      //    break;
      //  case "SB_DELTA":
      //    obj = item.secBrokerDelta;
      //    break;
      //  case "SV_STATUS":
      //    obj = item.secVndPricingStatusType;
      //    break;
      //  case "SV_PRICE":
          
      //    switch (item.secVndPriceStatus) {
      //    case "R":
      //      obj = null;
      //      break;
      //    default:
      //      obj = item.secVndrPrice;
      //      break;
      //    }
      //    break;
      //  case "SV_COMMENTS":
      //    obj = item.secVndPriceComments;
      //    break;
      //  case "SV_SEC_ID":
      //    obj = item.secVndSecurityId;
      //    break;
      //  case "MO_PB_COMMENTS":
      //    obj = item.middleOffPBComments;
      //    break;
      //  case "MO_PV_COMMENTS":
      //    obj = item.middleOffPVComments;
      //    break;
      //  case "MO_SB_COMMENTS":
      //    obj = item.middleOffSBComments;
      //    break;
      //  case "MO_SV_COMMENTS":
      //    obj = item.middleOffSVComments;
      //    break;
      //  case "DV01":
      //    obj = item.dV01;
      //    break;
      //  case "FUNDING_LEVEL":
      //    obj = item.fundingLevel;
      //    break;
      //  case "PAR_SPREAD":
      //    obj = item.parSpread;
      //    break;
      //  case "EX_OPT_PRICE":
      //    obj = item.exoticOptionValue;
      //    break;
      //  case "THEO_PRICE":
      //    obj = item.theoreticalPrice;
      //    break;
      //  case "THRESHOLD":
      //    obj = item.threshold;
      //    break;
      //  case "ACCNT_PRC":
      //    obj = item.prevDayAccountingPrice;
      //    break;
     }
   if (obj == null) {
     obj = "";
   }   
  //  else if (typeof(obj) === 'number' ) {
  //    switch (item.cusip) {
  //    case "FUNDING_LEVEL":
  //    case "THRESHOLD":
  //    case "PAR_SPREAD":
  //      obj = obj;// "obj | 'number':'3.1-5'"; //obj.formatNumber();
  //  if(obj.includes("-")&& obj.length >=13)
  //    {
  //      obj= obj.substring(0, 12);
  //    }
  //      break;
  //    default:
  //      obj = obj;//{obj | number : 2} ; //getDoubleStr((BigDecimal) object, PRC_DOUBLE_FORMAT);''
  //    }
  //  }  
     return(obj);
     
 }

 getRowDataItemClass(baseClass, status): string {     
  return (status === 'Rejected'? baseClass + '-highlight' : baseClass);
 }

 getTableHeightStyle(): string {  
  return 'calc(100vh - 510px)';
}

 numberCheck(e, comp, type, precision, scale) {
  var flag = true;
  var keyCode = e.keyCode;
  
  // Enables delete, backspace & arrows keys (left, right, up, down) in firefox, 
  // backspace is 8 others are 0. 
  if(e.which == 0 || e.which == 8) {
    return true;
  }
   
  var value = comp.toString().replace(/\u200B/g, '');
    value  = value.trim()
  var str = value;
  var maxLength = -1;
  if (document.all) {keyCode = e.keyCode;} 
  else {keyCode = e.which;}
//	alert(keyCode);
  
  
  if(type == 'n') {maxLength = precision;}
  else if(type == 'nf') {maxLength = precision + scale + 1;} // 1 for dot (.)
  else if(type == 'nnf') {maxLength = precision + scale + 2;} // 1 for dot (.), 1 for minus sign (-)

  if (str.length == maxLength) {return false;}

  // Only number allowed	
  if(keyCode > 47 && keyCode < 58){flag = true;}
  else{flag = false;}

  // Dot
  if ((type == "nf" || type == "nnf") && str.indexOf(".") == -1 && keyCode == 46) {
    flag = true;
  }

  // Negative number (minus sign)
  if (type == "nnf" && str.indexOf("-") == -1 && str.length == 0 && keyCode == 45) {
    flag = true;
  }
  
  return flag;
}
}
