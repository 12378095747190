import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from './env.service';

const httpOptions = {
  headers: new HttpHeaders({
   'Content-Type':  'application/json'           
  })
};

@Injectable({
  providedIn: 'root'
})
export class BrokerserviceService {

 constructor( private envService: EnvService, private http: HttpClient) { }

 showTodayDate() { 
    let ndate = new Date(); 
    return ndate; 
 } 

 /**
  * Get Securities Based on Role Type
  *  
  * @param roleType - Role Type
  * @returns list of securities
  */
 getSecuritiesBasedOnRoleType(roleType: string): Observable<any> {   
  console.log(`Get Securities based on role type - ${roleType}`);
  let url =  this.envService.getApiBaseUrl()+'securitiesBasedonRoleType?roleType=' + roleType;  
  console.log(`Get Securities based on role type URL - ${url}`);
  return this.http.get(url, httpOptions);
}

  /**
   * Method to Save Security Prices
   * @param securityPriceData - Security Price Data
   * @returns 
   */
  saveSecurityPrices(securityPriceData): Observable<any> { 
    console.log(`Save Security Prices`);
    let url = this.envService.getApiBaseUrl()+'saveSecurityPrices'
    console.log(`Save Security Prices URL - ${url}`);
    return this.http.post(url, securityPriceData, httpOptions);
  }

  /**
   * Method to upload broker file
   * @param file 
   * @returns 
   */
  uploadBrokerFile(file : File): Observable<any>{
    
    let url = this.envService.getApiBaseUrl()+'uploadBrokerFile'
    console.log(`Upload file URL - ${url}`);
    let formData = new FormData();
    formData.set('brokerFile',file);
    return this.http.post(url, formData);
  }

  /**
   * Method to get Middle Office Notification message
   * @returns Middle Office Notification message
   */
  getMOPSNotification(): Observable<any>{
    let url = this.envService.getApiBaseUrl()+'middleOfficeNotification';
    console.log(`MOPS Notification URL - ${url}`);
    return this.http.get(url,httpOptions);
  }

  /**
   * Get Disclaimer text for a given source id and asset category
   * @param sourceId 
   * @param assetCategory 
   * @returns  Disclaimer text
   */
  getDisclaimerText(sourceId : number, assetCategory: string) : Observable<any>{
    const disclaimerTextUrl = this.envService.getApiBaseUrl()+`disclaimerText?sourceId=${sourceId}&assetCategory=${assetCategory}`;
    console.log(`Disclaimer Text URL - ${disclaimerTextUrl}`);
    return this.http.get(disclaimerTextUrl,httpOptions);
  }  

}
