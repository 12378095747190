export enum PricingAttrType{
    PB_STATUS, PB_PRICE, PB_COMMENTS,PB_SEC_ID, PB_REJ_REASON_CODE, PB_MKT_VAL, PB_DELTA, PB_VOLATILITY, PB_UNDL_SEC_PRICE,
	PV_STATUS, PV_PRICE, PV_COMMENTS,PV_SEC_ID, PV_REJ_REASON_CODE, 
	SB_STATUS, SB_PRICE, SB_COMMENTS,SB_SEC_ID, SB_REJ_REASON_CODE, SB_MKT_VAL, SB_DELTA, SB_VOLATILITY, SB_UNDL_SEC_PRICE,
	SV_STATUS, SV_PRICE, SV_COMMENTS,SV_SEC_ID, SV_REJ_REASON_CODE, 
	AB_STATUS, AB_PRICE, AB_SEC_ID, AB_MKT_VAL,
	MO_PB_COMMENTS, MO_PV_COMMENTS, MO_SB_COMMENTS, MO_SV_COMMENTS,  
	THEO_PRICE,CE_THEO_PRICE, RISK_THEO_PRICE, MARKIT_THEO_PRC, 
	DV01, CE_DV01, PV01, CE_PV01, RISK_DV01, MARKIT_DV01, RECOVERY_RATE, CE_RECOVERY_RATE,
	PAR_SPREAD, CE_PAR_SPREAD,MARKIT_PAR_SPREAD,
	FUNDING_LEVEL, CE_FUNDING_LEVEL,
	THRESHOLD, THRESHOLD_LVL,EX_OPT_PRICE,PRICE_SENT_FLAG,
	ACCRUED_INTEREST,FX_RATE, ACCNT_PRC,
	NOTIONAL_AMT, PRC_CRRNCY, SPLST_PRC, PRI_TRADER, SEC_TRADER,
	DUMMY_FLAG,
	DURATION,
	TRDR_ACTION,
	ACCNT_PRC_SOURCE, LCH_ID, LCH_DIRTY_PRC
}