import {NgModule, APP_INITIALIZER} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule } from '@angular/forms';
import {DecimalPipe} from '@angular/common';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrokerWsComponent} from './mops/components/broker-ws/broker-ws.component';
import { HttpClientModule } from '@angular/common/http';
import { BrokerserviceService } from './mops/services/brokerservice.service';
import { BrokerDRVDatatableComponent } from './mops/components/broker-drv-datatable/broker-drv-datatable.component';
import { BrokerFIDatatableComponent } from './mops/components/broker-fi-datatable/broker-fi-datatable.component';
import { SortDirective } from './mops/directive/sort.directive';
import { LoginComponent } from './login/components/login.component';
import { authInterceptorProviders } from './mops/helpers/auth.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { EnvService } from './mops/services/env.service';
import { FileUploadDialogComponent } from './mops/dialogs/file-upload-dialog/file-upload-dialog.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { DisclaimerDialogComponent } from './mops/dialogs/disclaimer-dialog/disclaimer-dialog.component';

import {OverlayModule} from '@angular/cdk/overlay';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';

@NgModule({
    declarations: [
        AppComponent,        
        BrokerWsComponent,
        BrokerDRVDatatableComponent,
        BrokerFIDatatableComponent,
        SortDirective,
        LoginComponent,
        FileUploadDialogComponent,
        DisclaimerDialogComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        NgxSpinnerModule,
        MatDialogModule,
        OverlayModule,
        MatButtonModule,
        MatInputModule,
        AppRoutingModule
    ],
    exports: [
        AppComponent,
        LoginComponent,
        BrokerFIDatatableComponent,
        FileUploadDialogComponent,
        AppRoutingModule
    ],
    bootstrap: [AppComponent],
    providers: [
        BrokerserviceService,
        DecimalPipe,
        authInterceptorProviders,
        {
            provide: APP_INITIALIZER,
            useFactory: (envService: EnvService) => () => envService.init(),
            deps: [EnvService],
            multi: true
          },
        { provide: MatDialogRef, useValue: {} }

    ]
})
export class AppModule { }
