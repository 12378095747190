import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvService } from './env.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
  })
  export class AuthService {
    constructor(private envService: EnvService, private http: HttpClient) { }
  
    /**
     * Method to login and return OAuth token
     * @param username - User Name  
     * @param password  - Password
     * @returns OAuth token
     */
    login(username: string, password: string): Observable<any> {
      return this.http.post(this.envService.getApiBaseUrl()+'oauthToken', {
        username,
        password
      }, httpOptions);
    }
    
    getUserProfileData(username: string, accessToken: string): Observable<any> {        
        console.log(`Getting user profile data for the user : ${username}`);        
        //Add access token
        httpOptions.headers.append('Authorization','Bearer '+ accessToken);      
        const url = this.envService.getApiBaseUrl() + 'userProfile?userId='+ username.trim().toUpperCase();        
        console.log('url: '  + url);
         return this.http.get(url, httpOptions);
      }
    
  }