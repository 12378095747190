import { Injectable } from '@angular/core';

export enum Environment {
    Prod = 'prd',
    Staging = 'stg',
    Test = 'tst',
    Dev = 'dev',
    Local = 'local',
  }

  @Injectable({ providedIn: 'root' })
  export class EnvService {
    private _env: Environment;
    private _apiBaseUrl: string;
    private _intApiBaseUrl: string;
  
    getEnv(): Environment {
      return this._env;
    }
  
    getApiBaseUrl(): string {
      return this._apiBaseUrl;
    }

    getIntApiBaseUrl(): string{
      return this._intApiBaseUrl;
    }
  
    constructor() {}
  
    init(): Promise<void> {
      return new Promise(resolve => {
        this.setEnvVariables();
        resolve();
      });
    }
  
    private setEnvVariables(): void {
      const hostname = window && window.location && window.location.hostname;
      console.log(`Host Name - ${hostname}`);  
      if (/^.*localhost.*/.test(hostname)) {
        this._env = Environment.Local;
         this._apiBaseUrl = 'https://pricing-dev.putnam.com/service/';
        this._intApiBaseUrl = 'https://pricing-aws-dev.putnam.com/services/rest/';
      } else if (/.*dev.putnam.com/.test(hostname)) {
        this._env = Environment.Dev;
        this._apiBaseUrl = 'https://pricing-dev.putnam.com/service/';
        this._intApiBaseUrl = 'https://pricing-aws-dev.putnam.com/services/rest/';
      } else if (/.*tst.putnam.com/.test(hostname)) {
        this._env = Environment.Test;
        this._apiBaseUrl = 'https://pricing-tst.putnam.com/mops/v1/';
        this._intApiBaseUrl = 'https://pricing-aws-tst.putnam.com/services/rest/';
      } else if (/.*stg.putnam.com/.test(hostname)) {
        this._env = Environment.Staging;
        this._apiBaseUrl = 'https://pricing-stg.putnam.com/mops/v1/';
        this._intApiBaseUrl = 'https://pricing-aws-stg.putnam.com/services/rest/';
      } else if (/pricing?(-east|-west)?.putnam.com/.test(hostname)) {
        this._env = Environment.Prod;
        this._apiBaseUrl = `https://${hostname}/service/`;
        this._intApiBaseUrl = 'https://pricing-aws.putnam.com/services/rest/';
      } else {
        console.warn(`Cannot find environment for host name ${hostname}`);
      }
      console.log(`Environment is ${this._env}`);
      console.log(`API Base URL is ${this._apiBaseUrl}`);
    }
  } 