import { HTTP_INTERCEPTORS, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { TokenStorageService } from '../services/token-storage.service';
import { Router } from '@angular/router'
import { map, catchError } from 'rxjs/operators';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private token: TokenStorageService, private router: Router) { }
   

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {        
        let authReq = req;
        //Check for Token expiration
        if(!req.url.endsWith('oauthToken') && this.isTokenExpired()) {
          this.relogin();          
          //Cancel request
          return of(undefined);
        }
        const token = this.token.getToken();
        if (token != null) {
          authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
        }        
        return next.handle(authReq).pipe(
          // Catch the 401 and route to relogin        
          catchError((err) => {
              // If the request is unauthorized, try refreshing the token before restarting.
              console.log('Error occurred in interceptor - ' + req.url);
              console.log(JSON.stringify(err));
              if (err instanceof HttpErrorResponse && !req.url.endsWith('oauthToken') && err.status === 401) {
                  console.log('Relogin');                  
                  this.relogin();
              } else {
                  return observableThrowError(err);
              }
          })
      );           
     }

     relogin(): void {
        //Clear Session Storage and redirect to login page
        this.token.signOut();
        this.router.navigate(['/login', {errorMessage: 'User Session Expired. Please Login'}]);
     } 

     isTokenExpired(): boolean  {
      try {                    
          const { exp } = JSON.parse(this.token.getAuthClaims()) as {
              exp: number;
          };
          const expirationDatetimeInMilliSeconds = exp * 1000;
          //console.log(`Token expiration date time in milli-seconds - ${expirationDatetimeInMilliSeconds}`);          
          return Date.now() >= expirationDatetimeInMilliSeconds;          
          
      } catch {
          return true;
      }
  }; 

}

export const authInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ];