import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { TokenStorageService } from './mops/services/token-storage.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    isLoggedIn = false;
    constructor(private tokenStorageService: TokenStorageService, private router: Router) {
    }

    ngOnInit() {
        let token = this.tokenStorageService.getToken();
        if(token) {
            this.isLoggedIn = true;
        }
        
        if(this.isLoggedIn){
            console.log('User Logged In');
            //If the user logged in - forward to dashboard
            this.router.navigate(['/dashboard']);
        }
    }

}
