import { Component, OnInit,Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/mops/services/auth.service';
import { TokenStorageService } from 'src/app/mops/services/token-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    constructor(private authService: AuthService, private tokenStorage: 
      TokenStorageService, private router: Router, private spinnerService: NgxSpinnerService, private route: ActivatedRoute) { 

      }    

    form: any = {
        username: null,
        password: null
      };
      isLoggedIn = false;
      isLoginFailed = false;
      errorMessage = '';
      
    //Event Emitter for user logged in event
    @Output() loggedInEvent = new EventEmitter<boolean>();   
    

    ngOnInit(): void {
        console.log(`Login Component - ngOnInit`); 
        this.route.params.subscribe(parameter => {
          console.log(`Parameters - ${JSON.stringify(parameter)}`);
          this.errorMessage = parameter && parameter.errorMessage || '';
        });        
        //Hide the spinner
        this.spinnerService.hide();        
        if (this.tokenStorage.getToken()) {
            this.isLoggedIn = true;            
        }
    }

    onSubmit(): void {        
        //Reset Error Message Property
        this.errorMessage = '';
        this.spinnerService.show();
        const { username, password } = this.form;
        if(!username || !password) {
          this.errorMessage = 'Username/Password cannot be blank';
          this.spinnerService.hide();
          return;
        }
        console.log(`User name is ${username}`);    
        this.authService.login(username, password).subscribe(
          data => {                                    
            //Save Access Token in Session Storage  
            this.tokenStorage.saveToken(data.accessToken); 
            //Save Auth Claims
            this.tokenStorage.saveAuthClaims(JSON.stringify(data.claims)); 
            //Get user profile data
            this.authService.getUserProfileData(username, data.accessToken).subscribe(userProfile => {               
                //Save user profile data in the session storage
                this.tokenStorage.saveUserProfile(JSON.stringify(userProfile));
                this.isLoginFailed = false;
                this.isLoggedIn = true; 
                //Emit user loggedIn event
                this.loggedInEvent.emit(true);
                //Hide the spinner
                this.spinnerService.hide();
                //After Successful login -> Navigate to dashboard
                this.router.navigate(['/dashboard']);

            }, err => {
                console.log(`Error occured while fetching user profile data - ${JSON.stringify(err)}`);
                this.errorMessage = 'Error occured while fetching user profile data. Please contact Putnam Helpdesk';
                this.isLoginFailed = true;
                this.loggedInEvent.emit(false);
                //Remove Access Token from Session Storage
                this.tokenStorage.signOut();
                this.spinnerService.hide();
            });                                   
          },
          err => {
            console.log(`Error - ${err}`);
            //Set the error property and display in the UI
            this.errorMessage = 'An invalid user name or password was entered. Please try again';
            this.isLoginFailed = true;
            this.loggedInEvent.emit(false);
            this.spinnerService.hide();
            
          }
        );
      }
    
      reloadPage(): void {        
        window.location.reload();
      }

}