import { Injectable } from '@angular/core';
const TOKEN_KEY = 'auth-token';
const USER_PROFILE_KEY = 'user-profile';
const AUTH_CLAIMS_KEY = 'auth-claims';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() { }

  signOut(): void {
    window.sessionStorage.clear();
  }

  public saveUserProfile(userProfile: string): void {
    window.sessionStorage.removeItem(USER_PROFILE_KEY);
    window.sessionStorage.setItem(USER_PROFILE_KEY, userProfile);
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);    
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public saveAuthClaims(claims: any): void {
    window.sessionStorage.removeItem(AUTH_CLAIMS_KEY);    
    window.sessionStorage.setItem(AUTH_CLAIMS_KEY, claims);
  }

  public getAuthClaims(): any | null {
    return window.sessionStorage.getItem(AUTH_CLAIMS_KEY);
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public getUserProfile(): string {
      return window.sessionStorage.getItem(USER_PROFILE_KEY);
  }
 
}