export class Message {
    putnamId: string;
    message: string;
    assetGroup: string;
    time : string;
    type: string;

    constructor(putnamId, message, assetGroup, time, type){
        this.putnamId = putnamId;
        this.message = message;
        this.assetGroup = assetGroup;
        this.time = time;
        this.type = type;
    }
}

export enum MessageTypes{
    ERROR = 'ERROR', SUCCESS = 'SUCCESS'
}
