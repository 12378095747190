import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.css']
})
export class FileUploadDialogComponent implements OnInit {

  dialogData : any;
  

  constructor(public dialogRef: MatDialogRef<FileUploadDialogComponent>) {
    this.dialogData = {file : null, upload: false}
   }

  ngOnInit(): void {
  }

  addFile(event){
    if(event.target.files && event.target.files.length > 0)
      this.dialogData.file = event.target.files[0];
  }

  upload(){
    this.dialogData.upload = true;
    this.dialogRef.close(this.dialogData);
  }

  cancel(): void {
    this.dialogData.upload = false;
    this.dialogRef.close(this.dialogData);
  }


}
