import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/components/login.component';
import { BrokerWsComponent } from './mops/components/broker-ws/broker-ws.component';
const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'dashboard', component: BrokerWsComponent },
    { path: '' , redirectTo: 'login', pathMatch: 'full'}
]
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }