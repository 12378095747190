import { Component, OnInit ,ViewChild} from '@angular/core';
import { BrokerserviceService } from '../../services/brokerservice.service';
import { BrokerFIDatatableComponent } from '../broker-fi-datatable/broker-fi-datatable.component';
import { BrokerDRVDatatableComponent } from '../broker-drv-datatable/broker-drv-datatable.component';
import * as XLSX from 'xlsx'; 
import {DecimalPipe} from '@angular/common';
import { TokenStorageService } from '../../services/token-storage.service';
import { Router } from '@angular/router';
import { formatDate } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner"; 
import {Message, MessageTypes} from './../../models/message';
import {Utils} from './../../util/utils';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {FileUploadDialogComponent} from './../../dialogs/file-upload-dialog/file-upload-dialog.component';
import { interval, timer } from 'rxjs';
import {DisclaimerDialogComponent} from './../../dialogs/disclaimer-dialog/disclaimer-dialog.component';

@Component({
  selector: 'app-broker-ws',
  templateUrl: './broker-ws.component.html',
  styleUrls: ['./broker-ws.component.css']
})
export class BrokerWsComponent implements OnInit {

  todayDate;
  userId:string;
  expected:number = 0;
  pending:number = 0;
  priced:number = 0;
  rejected:number = 0;
  accepted:number = 0;
  difference:number = 0;
  data: any[];
  filterData:string;
  secCatType:string;
  assetGrpType:string;
  message:Message[];
  sourceName:string;
  securityType:any[];
  previoustabId:string;
  previousAId:string;
  fiDataArray:any[];
  fiAllData:any[];
  fiFilterData:any[];
  DRVDataArray:any[];
  DRVAllData:any[];
  DRVFilterData:any[];
  
  fileName:string;
  file:File;
  arrayBuffer:any;
  filelist:any;

  OTC_access:boolean;
  MBS_access:boolean;
  HY_access:boolean;
  CV_access:boolean;
  FOR_access:boolean;
  PP_access:boolean;
  HG_access:boolean;  

  //User Profile Object
  userProfile: any;
  globalSt:string = 'All';
  globaltype:string = 'All';
  
  mopsNotificationMessage : string;

  private sourceId: number;

 
  @ViewChild(BrokerDRVDatatableComponent) drvDataTableComp:BrokerDRVDatatableComponent;
  @ViewChild(BrokerFIDatatableComponent) fiDataTableComp:BrokerFIDatatableComponent;
  constructor(private bkrService:BrokerserviceService,private decimalPipe: DecimalPipe,
     private tokenStorageService: TokenStorageService, private router: Router,
     private SpinnerService: NgxSpinnerService, public dialog : MatDialog) { 
    
  }

  ngOnInit(): void {
    console.log('Broker Workspace component - ngOnInit'); 
    //Get the user profile object from Session Storage
    this.userProfile = this.tokenStorageService.getUserProfile() ? JSON.parse(this.tokenStorageService.getUserProfile()) : {};
    this.sourceName = this.getSourceName(this.userProfile);
    this.userId = this.getUserId(this.userProfile);    
    console.log(`Logged in user - ${this.userId}`);
    this.sourceId = this.getSourceId(this.userProfile);
    this.showUserTabs(this.userProfile);
    this.getSecurities(this.getRoleType(this.assetGrpType));
    this.message = [];
    //Subscribe Middle Office Notification Message - Every 5 minutes  
    /*timer(0 ,5*60*1000).subscribe(() =>{
      this.bkrService.getMOPSNotification().subscribe(data=>{        
          this.mopsNotificationMessage = data && data.message || '';        
      },error=>{
        console.log("Error occurred while fetching notification - ",error);
      })
    });*/
    this.bkrService.getMOPSNotification().subscribe(data=>{        
      this.mopsNotificationMessage = data && data.message || '';        
    },error=>{
      console.log("Error occurred while fetching notification - ",error);
    });

  }
  ngAfterViewChecked() {
    this.setBVTabBg(this.assetGrpType);
  }   
  
  getSourceId(userProfile: any) {
    return userProfile.source && userProfile.source.id;
  }

  getSourceName(userProfile){
     return userProfile.source && userProfile.source.sourceName;
  }

  getUserId(userProfile){
    return (userProfile.firstname || '') + ' ' + (userProfile.lastname || '');
  }

  showUserTabs(userProfile){
    let userRolesList:any[] = userProfile.userRoles || [];
    let userRolesArr = [];
    userRolesList.map((userRole) => {
      console.log('userRole.roleType ' + userRole.roleType);
      userRolesArr.push(userRole.roleType);
    });
    this.MBS_access = userRolesArr.includes('PRICE_BROKER_MBS');
    this.OTC_access = userRolesArr.includes('PRICE_BROKER_OTC');
    this.HY_access = userRolesArr.includes('PRICE_BROKER_HY');
    this.PP_access = userRolesArr.includes('PRICE_BROKER_PP');
    this.FOR_access = userRolesArr.includes('PRICE_BROKER_FB');
    this.CV_access = userRolesArr.includes('PRICE_BROKER_CV');
    this.HG_access = userRolesArr.includes('PRICE_BROKER_HG');

  
			/* logical hirarchy to present selected assetGrp, in case of multiple assetGroups */
			if (this.OTC_access) {
				this.assetGrpType = 'OTC';
			} else if (this.MBS_access) {
				this.assetGrpType = 'MBS';
			} else if (this.HY_access) {
                this.assetGrpType = 'HY';
			} else if (this.CV_access) {
				this.assetGrpType = 'CV';
			} else if (this.FOR_access) {
				this.assetGrpType = 'FOR';
			} else if (this.PP_access) {
				this.assetGrpType = 'PP';
			} else if(this.HG_access){
        this.assetGrpType = 'HG';
				}
        else{
          this.assetGrpType = 'MBS';
        }
  }

  getSecurities(roleType: string){ 
    this.SpinnerService.show();    
    this.bkrService.getSecuritiesBasedOnRoleType(roleType).subscribe( data=> {      
      console.log('secCatType ' + this.secCatType);
      console.log('assetGrpType ' + this.assetGrpType);
      this.data = data;
      this.getSummaryList(this.data);
      if(this.secCatType === 'FI'){
           this.populateFIGrid(this.data);
      }
      else{
          this.populateDRVGrid(this.data);
      }
      this.securityType = this.getSecurityTypeList(this.data);
      var securityStatusdropDown = document.getElementById("securityStatusListBox") as HTMLSelectElement;  
      securityStatusdropDown.selectedIndex = 0;
      this.SpinnerService.hide(); 
    },
    (error) => {                              //Error callback
      console.error('error caught in component',error);
       this.populateMsgListBox("Error occurred while calling SecuritiesBaseOnRoleType API", MessageTypes.ERROR);
       this.SpinnerService.hide(); 
    });
  }

  onRefresh(){
    this.getSecurities(this.getRoleType(this.assetGrpType));
  }
 
  logout(): void {
    //Remove token from Session Storage
    console.log(`Clearing Session Storage`);
    this.tokenStorageService.signOut();
    //Navigate to home page after successful logout    
    this.router.navigate(['/home']);
  }

  openUserGuide(): boolean {
    console.log('Opening User Guide');
    window.open('/assets/userguide/User_Guide_Broker_v1.pdf',"User_Guide","left=200,top=200,width=800,height=500,resizable=1");
    return false;
  }
  
  getRoleType(assetGrpType){
    let roleType = '';
    switch (assetGrpType) {
     
              case 'OTC':
                  roleType = 'PRICE_BROKER_OTC';
                  this.secCatType = 'DRV';
                  break;
              case 'MBS':
                  roleType = 'PRICE_BROKER_MBS';
                  this.secCatType = 'FI';
                  break;
              case 'HY':
                  roleType = 'PRICE_BROKER_HY';
                  this.secCatType = 'FI';
                  break;
              case 'CV':
                  roleType = 'PRICE_BROKER_CV';
                  this.secCatType = 'FI';
                  break;
              case 'FOR':
                  roleType = 'PRICE_BROKER_FB';
                  this.secCatType = 'FI';
                  break;
              case 'PP':
                  roleType = 'PRICE_BROKER_PP';
                  this.secCatType = 'FI';
                  break;
              case 'HG':
                  roleType = 'PRICE_BROKER_HG';
                  this.secCatType = 'FI';
                  break;
  }
  return roleType;
  }


  populateFIGrid(secList:any[]){
    let prepareData = [];
    let notionl:string = null;    
    secList.map((item) => {
      let bkrFIObj = new BrokerFIDatatableComponent();
      bkrFIObj.status = this.getPricingStatusFIORDRV(item,'PRIMARY_BROKER');
      bkrFIObj.cusip = item.cusip;
      bkrFIObj.securityName = item.securityName;
      notionl =  item.notional+"";
      bkrFIObj.dealNotional =notionl==null?"":notionl.replace(/\B(?=(\d{3})+(?!\d))/g, ",");  
      bkrFIObj.mktValue = this.getMktDescription(item.marketOrUnitizedValue);
      bkrFIObj.yesterdayUnitizedPrice = this.decimalPipe.transform(item.prevDaySourcePrice, '0.1-12');
      bkrFIObj.priceDiff =  this.decimalPipe.transform(bkrFIObj.getpriceDiff(item), '0.1-12');
      bkrFIObj.pricingCurrency = item.prcCurrencyCode;
      bkrFIObj.assetCurrency = item.localCurrencyCode;
      bkrFIObj.securityType = item.securityTypeDesc;
      bkrFIObj.maturityDate = item.maturityDate;
      bkrFIObj.dailyMonthly = 'Daily';
      bkrFIObj.brokerSecurityId = item.brokerSecurityId;
      
      bkrFIObj.brokerSecId = bkrFIObj.getAttribute(item,'secIdAttr');
      bkrFIObj.brokerSecIdOnLoad = bkrFIObj.brokerSecId;
      bkrFIObj.price = bkrFIObj.getAttribute(item,'priceIdAttr');
      bkrFIObj.priceOnLoad = bkrFIObj.price;
      
      prepareData.push(bkrFIObj);
    });
    if(prepareData.length == 0){
      this.populateMsgListBox(' No positions available to price',MessageTypes.SUCCESS);
    }
    this.fiAllData = prepareData;
    this.fiDataArray = this.fiAllData;
  }

  populateDRVGrid(secList:any[]){
    let prepareData = [];    
    secList.map((item) => {
      let bkrDRVObj = new BrokerDRVDatatableComponent();
      bkrDRVObj.status = this.getPricingStatusFIORDRV(item,'PRIMARY_BROKER');
      bkrDRVObj.cusip = item.cusip;
      bkrDRVObj.securityName = item.securityName;
      bkrDRVObj.dealNotional = item.notional;
      bkrDRVObj.mktValue = this.getMktDescription(item.marketOrUnitizedValue);
      bkrDRVObj.yesterdayUnitizedPrice = this.decimalPipe.transform(item.prevDaySourcePrice, '0.1-12');
      bkrDRVObj.pricingCurrency = item.prcCurrencyCode;
      bkrDRVObj.fxRate = item.fxRate;
      bkrDRVObj.assetCurrency = item.localCurrencyCode;
      bkrDRVObj.securityType = item.securityTypeDesc;
      bkrDRVObj.maturityDate = item.maturityDate;
      bkrDRVObj.dailyMonthly = item.monthlyFl == 'Y' ? 'Monthly':'Daily';
      bkrDRVObj.comments = item.middleOffPBComments;
      bkrDRVObj.validSecType = item.securityType;
      bkrDRVObj.mktValueFl = item.marketOrUnitizedValue == 'MV' ? true:false;
      bkrDRVObj.accruedInt = item.accruedInt;
      bkrDRVObj.brokerSecurityId = item.brokerSecurityId;
      bkrDRVObj.brokerMktValue = item.brokerMktValue;
      bkrDRVObj.swapTypeCode = item.swapTypeCode;
      bkrDRVObj.prevDayPriceDetail = item.prevDayPriceDetail;

      bkrDRVObj.brokerSecId = bkrDRVObj.getAttribute(item,'secIdAttr');
      bkrDRVObj.brokerSecIdOnLoad = bkrDRVObj.brokerSecId;
      bkrDRVObj.price = bkrDRVObj.getAttribute(item,'mktId');
      bkrDRVObj.priceOnLoad = bkrDRVObj.price;
      bkrDRVObj.underlyingSecurityPrice = bkrDRVObj.getAttribute(item,'unpriceIdAttr');
      bkrDRVObj.underlyingSecurityPriceOnLoad = bkrDRVObj.underlyingSecurityPrice;
      bkrDRVObj.volatility = bkrDRVObj.getAttribute(item,'volAttr');
      bkrDRVObj.volatilityOnLoad = bkrDRVObj.volatility;
      bkrDRVObj.delta = bkrDRVObj.getAttribute(item,'delAttr');
      bkrDRVObj.deltaOnLoad = bkrDRVObj.delta;
      bkrDRVObj.displaySourceComment = bkrDRVObj.isDisplaySourceComment(item,'PRIMARY_BROKER');
      prepareData.push(bkrDRVObj);
    });
    if(prepareData.length == 0){
      this.populateMsgListBox('No positions available to price',MessageTypes.SUCCESS);
    }
    this.DRVAllData = prepareData;
    this.DRVDataArray = this.DRVAllData;
  }

  getMktDescription(value) {
		switch (value) {
		case 'MV':
			return "Market Value";
		case 'UV':
			return "Unitized Value";
		default:
			return "Unitized Value";
		}
  }

  getPricingStatusFIORDRV(fiOrDrvObj,sourceType) {
		let statusTypeStr = null;
		switch (sourceType) {
		case 'PRIMARY_BROKER':
			statusTypeStr = fiOrDrvObj.brokerStatus;
			break;
		case 'SECONDARY_BROKER':
			statusTypeStr = fiOrDrvObj.secBrokerPriceStatus;
			break;
		case 'PRIMARY_VENDOR':
			statusTypeStr = fiOrDrvObj.vendorPriceStatus;
			break;
		case 'SECONDARY_VENDOR':
			statusTypeStr = fiOrDrvObj.secVndPriceStatus;
			break;
		}
		return statusTypeStr != null ? this.getStatus(statusTypeStr) : null;
	}

  getStatus(status){
    let pricingStatus = '';
    switch (status) {
      
      case 'E':
          pricingStatus = 'Pending';
          break;
      case 'P':
          pricingStatus = 'Priced';
          break;
      case 'R':
          pricingStatus = 'Rejected';
          break;
      case 'A':
          pricingStatus = 'Accepted';
          break;
  }
  return pricingStatus;
  }
 
  
  populateMsgListBox(msg, type){
      let msgObj = new Message(null,msg,null,Utils.getCurrentTimeEST(), type);
      if(!this.message)
        this.message = [];
      this.message.push(msgObj);
      //Sort Messages based on time
      if(this.message.length > 0 ) {
        this.message.sort(this.messagesSortComparatorBasedOnTime);
      }
  }
  
  getSummaryList(secList:any[]){
      this.pending = 0;
      this.expected = 0;
      this.priced = 0;
      this.rejected = 0;
      this.accepted = 0;
      this.difference = 0;
      
      this.expected = secList.length;
      secList.map((item) => {
        let status : string = item.brokerStatus;
        switch (status) {
            case 'E':
                this.pending++;
                break;
            case 'P':
                this.priced++;
                break;
            case 'R':
                this.rejected++
                break;
            case 'A':
                this.accepted++;
                break;
        }
      });
      this.difference  = this.pending + this.rejected;
  }


  getSecurityTypeList(secList:any[]){
            let prepareData = [];
            let secType = '';
            let secTypeDesc = '';
            let map = new Map<string, string>();
            secList.forEach((item) => {
                secType = item.securityType;
                secTypeDesc = item.securityTypeDesc;
              map.set(secType, secTypeDesc);
            });
            console.log('map ' + map.size);
              map.forEach((value: string, key: string) => {
                let jsonObject = {
                  "key": key,
                  "value": value
                };
                prepareData.push(jsonObject);
              });
           return prepareData;
  }

  getSecurityStatusList(){
    let statusArr = ['Rejected','Pending','Priced','Accepted'];
    return statusArr;
  }

  onTabMousOver(id, aid) {
    var tab = document.getElementById(id);
    tab.style.backgroundColor = "rgb(68, 135, 233)";
    tab.style.cursor = "pointer";
    var atag = document.getElementById(aid);
    if (atag != null) {
      atag.style.color = "rgb(255, 255, 255)";
    }
  }

  onStatusMouseOut(id, aid) {
    var moverTab = document.getElementById(id);
    var atag = document.getElementById(aid);
    if (id != this.previoustabId && atag != null) {
      moverTab.style.backgroundColor = "rgb(213, 228, 243)";
      atag.style.color = "rgb(68, 135, 233)";
    } else {
      moverTab.style.backgroundColor = "rgb(68, 135, 233)";
    }
  }

  setBVTabBg(status) {
    var tab = null;
    var atag = null;
    if (status == "OTC") {
      tab = document.getElementById("tab1");
      if(tab != null) {
        tab.style.backgroundColor = "rgb(68, 135, 233)";
        atag = document.getElementById("hvr1");
        atag.style.color = "rgb(255, 255, 255)";
      }
    } else if (status == "MBS") {
      tab = document.getElementById("tab2");
      if(tab != null) {
        tab.style.backgroundColor = "rgb(68, 135, 233)";
        atag = document.getElementById("hvr2");
        atag.style.color = "rgb(255, 255, 255)";
      }
    } else if (status == "HY") {
      tab = document.getElementById("tab3");
      if(tab != null) {
        tab.style.backgroundColor = "rgb(68, 135, 233)";
        atag = document.getElementById("hvr3");
        atag.style.color = "rgb(255, 255, 255)";
      }
    } else if (status == "CV") {
      tab = document.getElementById("tab4");
      if(tab != null) {
        tab.style.backgroundColor = "rgb(68, 135, 233)";
        atag = document.getElementById("hvr4");
        atag.style.color = "rgb(255, 255, 255)";
      }
    } else if (status == "FOR") {
      tab = document.getElementById("tab5");
      if(tab != null) {
        tab.style.backgroundColor = "rgb(68, 135, 233)";
        atag = document.getElementById("hvr5");
        atag.style.color = "rgb(255, 255, 255)";
      }
    } else if (status == "PP") {
      tab = document.getElementById("tab6");
      if(tab != null) {
        tab.style.backgroundColor = "rgb(68, 135, 233)";
        atag = document.getElementById("hvr6");
        atag.style.color = "rgb(255, 255, 255)";
      }
    } else if (status == "HG") {
      tab = document.getElementById("tab7");
      if(tab != null) {
        tab.style.backgroundColor = "rgb(68, 135, 233)";
        atag = document.getElementById("hvr7");
        atag.style.color = "rgb(255, 255, 255)";
      }
    }
  
    if (atag != null && tab != null) {
      this.previoustabId = tab.id;
      this.previousAId = atag.id;
    }
  }

 onTabSelect(id, status, aid) {   
   if(this.confirmSwitchView()){
        if(!confirm("Warning! You have unsaved changes in the "+this.assetGrpType+" page.\n\nAre you sure you want to navigate away from this page?\n\nPress OK to continue, or Cancel to stay on the current page.")){
            return;
        }
   }
    try {
      if (this.previoustabId !== null) {
        var prevTab = document.getElementById(this.previoustabId);
        prevTab.style.backgroundColor = "rgb(225, 234, 254)";
      }
      if (this.previousAId !== null) {
        var prevATag = document.getElementById(this.previousAId);
        prevATag.style.color = "rgb(68, 135, 233)";
      }
      if (aid !== null && id !== null) {
        var selectedTag = document.getElementById(aid);
        selectedTag.style.color = "rgb(255, 255, 255)";
        var selectedTab = document.getElementById(id);
        selectedTab.style.backgroundColor = "rgb(68, 135, 233)";
        this.previoustabId = selectedTab.id;
          this.onStatusChange(status);
          this.previousAId = aid;
      }
    }
    catch (ex) {
      alert("Error: " + ex.name + "\n" + "Error Message: " + ex.message);
    }
  }

  confirmSwitchView(){
    let flag:boolean = false;
    let count:number = 0;
    if(this.secCatType === 'FI'){
      this.fiAllData.map((item) => {
         if(item.updateFlag){
         count++;
         }
      });
    }
    else{
      this.DRVAllData.map((item) => {
        if(item.updateFlag){
          count++;
          }
     });
    }

    return count > 0;
  }
 onStatusChange(status) {
    try {
      console.log('status ' + status);
      this.assetGrpType = status;
      this.getSecurities(this.getRoleType(this.assetGrpType));
    }
    catch (ex) {
      alert("Error: " + ex.name + "\n" + "Error Message: " + ex.message);
    }
  }

  filterSecTypeBVData(secType){ 
    this.globaltype = secType;
    let prepareData = [];    
    if(this.secCatType === 'FI'){
    this.fiAllData.map((item) => {
       if((secType === item.securityType || secType === 'All')   && (this.globalSt == 'All' || this.globalSt === item.status)){
        prepareData.push(item);
       }
    });
    this.fiFilterData = prepareData;
    this.fiDataArray = this.fiFilterData;
  }
  else{
     
    this.DRVAllData.map((item) => {
      if((secType === item.securityType || secType === 'All') && (this.globalSt == 'All' || this.globalSt === item.status)){
       prepareData.push(item);
      }
   });
   
   this.DRVFilterData = prepareData;
   this.DRVDataArray = this.DRVFilterData;
  
  }
  
  }

  filterSecStatusBVData(secStatus){
  
    let prepareData = [];   
    this.globalSt = secStatus;
    if(this.secCatType === 'FI'){
    this.fiAllData.map((item) => {
       if((secStatus === item.status || secStatus === 'All')  && (this.globaltype == 'All' || this.globaltype === item.securityType)){
        prepareData.push(item);
       }
    });
    this.fiFilterData = prepareData;
    this.fiDataArray = this.fiFilterData;
  }
  else{
    this.DRVAllData.map((item) => {
      if((secStatus === item.status || secStatus === 'All') && (this.globaltype == 'All' || this.globaltype === item.securityType)){
       prepareData.push(item);
      }
   });
   this.DRVFilterData = prepareData;
   this.DRVDataArray = this.DRVFilterData;
  }

 
  }

  exportexcel(): void 
  {
    let date = formatDate(this.bkrService.showTodayDate(), 'MMddyyyy', 'en-US');
    this.fileName = this.sourceName.replace(/\s/g, '+') + '-' + this.assetGrpType.toLowerCase()  + '-' + date + '.csv'
     const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(this.prepareDataForExport());
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     XLSX.writeFile(wb, this.fileName);
  }

  prepareDataForExport(){
    let exportArr = [];        
    if(this.secCatType === 'DRV'){
      (this.DRVDataArray || this.DRVAllData).map((DRVobj) => {
        let rowObj = {};
        rowObj['Putnam ID'] = DRVobj.cusip;
        rowObj['Broker Sec Id'] = DRVobj.brokerSecurityId;
        rowObj['Security Name'] = DRVobj.securityName;
        rowObj['Pricing Currency'] = DRVobj.pricingCurrency;
        rowObj['Price'] = DRVobj.mktValueFl?DRVobj.brokerMktValue : DRVobj.price;
        rowObj['Notional Amount'] = DRVobj.dealNotional;
        rowObj['Underlying Security Price'] = DRVobj.underlyingSecurityPrice;
        rowObj['Volatility'] = DRVobj.volatility;
        rowObj['Delta'] = DRVobj.delta;
        rowObj['Security Type'] = DRVobj.securityType;
        exportArr.push(rowObj);
     });
    }
    else{
      (this.fiDataArray || this.fiAllData).map((FIObj) => {
        let rowObj = {};
        rowObj['Putnam ID'] = FIObj.cusip;
        rowObj['Broker Sec Id'] = FIObj.brokerSecurityId;
        rowObj['Security Name'] = FIObj.securityName;
        rowObj['Pricing Currency'] = FIObj.pricingCurrency;
        rowObj['Price'] = FIObj.price;
        rowObj['Notional Amount'] = FIObj.dealNotional;
        rowObj['Underlying Security Price'] = '';
        rowObj['Volatility'] = '';
        rowObj['Delta'] = '';
        rowObj['Security Type'] = FIObj.securityType;
        exportArr.push(rowObj)
     });
    } 

    return exportArr;
  }

  importExcel(){
    let dialogRef = this.dialog.open(FileUploadDialogComponent);
    
    dialogRef.afterClosed().subscribe(result=>{
      let dialogData = result;
      if(dialogData.upload){
        if(dialogData.file){
          this.file = dialogData.file;
          this.addfile();
        }else{
          this.populateMsgListBox("No file is provided to upload",MessageTypes.ERROR);
        }
        
      }
     
    });
  }

  addfile(){   
  if(!Utils.isValidFile(this.file)){
    this.populateMsgListBox('Invalid file format. Please upload a text CSV file.',MessageTypes.ERROR);
    return;
  }
  this.SpinnerService.show(); 

  this.bkrService.uploadBrokerFile(this.file).subscribe(data=>{        
        let fileReader = new FileReader();    
        fileReader.readAsArrayBuffer(this.file);     
        fileReader.onload = (e) => {    
            this.arrayBuffer = fileReader.result;    
            var data = new Uint8Array(this.arrayBuffer);    
            var arr = new Array();    
            for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);    
            var bstr = arr.join("");    
            var workbook = XLSX.read(bstr, {type:"binary"});    
            var first_sheet_name = workbook.SheetNames[0];    
            var worksheet = workbook.Sheets[first_sheet_name];    
            //Convert to CSV Data Array  
            var csvDataArray = this.csvToArray(XLSX.utils.sheet_to_csv(worksheet));                      
            let updateCount = this.populateGridExcel(csvDataArray);             
            // Display Message
            if(updateCount > 0) {             
              let updateMessage = `Updated ${updateCount} securities from CSV file ${this.file.name}`;
              console.log(`${updateMessage}`);
              this.populateMsgListBox(updateMessage,MessageTypes.SUCCESS);
            }
    }
    this.SpinnerService.hide();
  },error=>{
    console.log("Error occurred while uploading file : ",error);
    this.populateMsgListBox('Error occurred while uploading file',MessageTypes.ERROR);
    this.SpinnerService.hide();
  })
   
} 

csvToArray(csvData, delimiter = ",") {  
  const headerConfig = {
    0: 'Putnam ID',
    1: 'Broker Sec Id',
    2: 'Security Name',
    3: 'Pricing Currency',
    4: 'Price',
    5: 'Notional Amount',
    6: 'Underlying Security Price',
    7: 'Volatility',
    8: 'Delta',
    9: 'Security Type'
  };
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = csvData.slice(0, csvData.indexOf("\n")).split(delimiter);
  //Overwrite header with default headers
  if(headers && headers.length > 0) {
    headers.forEach((element, index) => {
      headers[index] = headerConfig[index];
    });
  }  

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const dataString = csvData.slice(csvData.indexOf("\n") + 1).replace(/\n*$/, "");
  const rows = dataString.split("\n");
  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  const csvDataArray = rows.map(function (row) {    
      const values = row.split(delimiter);
      const el = headers.reduce(function (object, header, index) {
        object[header] = values[index];
        return object;
      }, {});
      return el;    
  });

  // return the array
  return csvDataArray;
}

populateGridExcel(secList:any[]) : number{
  let updateCount = 0;
  let errors = [];
  let secMap = null;
  let isDRV:boolean = false;  
  if(secList.length == 0){
    this.message = [new Message(null,'Error: the file being uploaded is empty',null,null,MessageTypes.ERROR)];
    return;
  }
  if(this.secCatType === 'DRV'){
    secMap = new Map<String, BrokerDRVDatatableComponent>();
    isDRV = true;
    this.DRVAllData.map((DRVobj) => {
      secMap.set(DRVobj.cusip,DRVobj);
   });
  }
  else{
    secMap = new Map<String, BrokerFIDatatableComponent>();
    this.fiAllData.map((FIobj) => {
      secMap.set(FIobj.cusip,FIobj);
   });
  }  
  let validCSVCusips = this.getValidCSVCusips(secList);
    secList.map((row) => {
        let cusip =row['Putnam ID'];
        let sourceSecId = row['Broker Sec Id'];
        let sourcePrice = +row['Price'];
        let undlSecPrice = +row['Underlying Security Price'];
        let volatility = +row['Volatility'];
        let delta = +row['Delta'];
        if (cusip != null && secMap.get(cusip) != null
					&& validCSVCusips.includes(cusip)) {
            let DRVOrFIObj = secMap.get(cusip);
           
            if(!(DRVOrFIObj.isEditable(DRVOrFIObj.status))){
                 	/* Update source price in data model */
                  // console.log('sourcePrice '  + sourcePrice);
              if (sourcePrice != null) {
                isDRV ? this.drvDataTableComp.syncBVdata('BROKER_PRICE',DRVOrFIObj,sourcePrice) : this.fiDataTableComp.syncBVdata('BROKER_PRICE',DRVOrFIObj,sourcePrice);
              }

              /* Update source sec id in data model */
              if (sourceSecId != null) {
                console.log('sourceSecId '  + sourceSecId);
                isDRV ? this.drvDataTableComp.syncBVdata('BROKER_SEC_ID',DRVOrFIObj,sourceSecId) : this.fiDataTableComp.syncBVdata('BROKER_SEC_ID',DRVOrFIObj,sourceSecId)
              }
              
              /* Update source price in data model */
              if (undlSecPrice != null && isDRV
                  && DRVOrFIObj.isValidOptionPST(DRVOrFIObj.validSecType) ) {
                    this.drvDataTableComp.syncBVdata('BROKER_UNDL_SEC_PRICE',DRVOrFIObj,undlSecPrice);
              }

              /* Update source price in data model */
              if (volatility != null && isDRV
                  && DRVOrFIObj.isValidOptionPST(DRVOrFIObj.validSecType) ) {
                    this.drvDataTableComp.syncBVdata('BROKER_VOLATILITY',DRVOrFIObj,volatility);
              }

              /* Update source price in data model */
              if (delta != null && isDRV 
                  &&  DRVOrFIObj.isValidOptionPST(DRVOrFIObj.validSecType) ) {
                    this.drvDataTableComp.syncBVdata('BROKER_DELTA',DRVOrFIObj,delta);
              }

              //Increment Update Count based on Update Flag
              if(DRVOrFIObj.updateFlag){
                updateCount++;
              }

            }
            else {
              // serviceResponse.addError(cusip,
              //     "Security cannot be edited",
              //     ErrorType.VALIDATION_ERROR);
              errors.push(new Message(cusip ,'Security cannot be edited',null,Utils.getCurrentTimeEST(),MessageTypes.ERROR));
            }
          }

          else {
            // TODO: Needs to revise
            if (cusip != null) {
              if (secMap.get(cusip) == null) {
                // serviceResponse.addError(cusip,
                //     "Security not found in the view",
                 //    ErrorType.VALIDATION_ERROR);
                 errors.push(new Message(cusip,'Security not found in the view',null,Utils.getCurrentTimeEST(),MessageTypes.ERROR));
               } 
              else if (!validCSVCusips.includes(cusip)) {
                 // serviceResponse.addError(cusip, " Duplicate Record",
                 //     ErrorType.VALIDATION_ERROR);
                 errors.push(new Message(cusip,'Duplicate Record',null,Utils.getCurrentTimeEST(),MessageTypes.ERROR));
               }
            } else {
              // serviceResponse.addError(cusip, "Invalid data row:" + row,
              //     ErrorType.VALIDATION_ERROR);
              errors.push(new Message(cusip,'Invalid data row:' + row,null,Utils.getCurrentTimeEST(),MessageTypes.ERROR));
              
            }
          }
      });

      /* Display all validation error messages */
		 if (errors.length > 0) {
		 	this.message = errors;
      //Sort Messages Based on time
      this.message.sort(this.messagesSortComparatorBasedOnTime); 
		 }
     console.log(`Securities Update Count - ${updateCount}`);
     return updateCount;

		// List<SecurityPrice> updatedTols = getUpdatedSecurityList();
		// int updateCount = (updatedTols != null) ? updatedTols.size() : 0;
		// if (updateCount > 0) {
		// 	UIUtils.showInfo("Updated " + updateCount
		// 			+ " securities from CSV file " + media.getName());
		// }
 
}

messagesSortComparatorBasedOnTime(message1, message2){  
  if (Date.parse(message1.time) < Date.parse(message2.time)) {
    return 1
  }
  if (Date.parse(message1.time) > Date.parse(message2.time)) {
    return -1
  }
  return 0
}

getValidCSVCusips(secList:any[]){
  let dupCSVCusips = [];
  let CSVCusips = [];
  let validCSVCusips = [];

  secList.map((row) => {
    let cusip =row['Putnam ID'];
    if (cusip != null) {
      if (CSVCusips.includes(cusip)) {
        /* enter duplicate cusips in dupCSVCusips */
        dupCSVCusips.push(cusip);
      } else {
        /* Put all unique CUSIPs in validCSVCusips */
        CSVCusips.push(cusip);
      }
    }
  });

  CSVCusips.map((cusip) => {
    if(!dupCSVCusips.includes(cusip)){
      validCSVCusips.push(cusip);
    }
  });

  return validCSVCusips;
}

  saveBVData(){    
    let restContextObj = {};
    let securityUpdateArr = [];
    let staleSecObjArr = [];
    let validationErrors= [];
    if(this.secCatType === 'FI'){
      this.fiAllData.map((fiObj) => {
        if(fiObj.validationsErrors.length > 0){
          for (var msg of fiObj.validationsErrors) {
            validationErrors.push(msg);
        }
        }
        if(fiObj.updateFlag){
          securityUpdateArr.push(this.prepareSecurityUpdateObj(fiObj));
        }
     });
    }
    else{
      this.DRVAllData.map((drvObj) => {
        console.log('length ' + drvObj.validationsErrors.length)
          if(drvObj.validationsErrors.length > 0){
            for (var msg of drvObj.validationsErrors) {
              validationErrors.push(msg);
          }
          }
        if(drvObj.updateFlag){
          if(drvObj.staleSecFlag){
            staleSecObjArr.push(drvObj);
          }
          else{
          securityUpdateArr.push(this.prepareSecurityUpdateObj(drvObj));
          }
        }
     });

     if(staleSecObjArr.length > 0){
      if (confirm('Current Days price for folowing securities  is equal to previous days price.  Do you want to continue?' + this.getCusips(staleSecObjArr))) {
        staleSecObjArr.map((drvObj) => {
          securityUpdateArr.push(this.prepareSecurityUpdateObj(drvObj));
       });
      } else {
        staleSecObjArr.map((drvObj) => {
          console.log('drvObj.priceOnLoad' + drvObj.priceOnLoad);
          drvObj.staleSecFlag = false;
          this.drvDataTableComp.syncBVdata('BROKER_PRICE',drvObj,drvObj.priceOnLoad+'')
       });
      }
     }
  }

  restContextObj['securityUpdates'] = securityUpdateArr;
  restContextObj['dataRefreshTime'] = this.bkrService.showTodayDate();
  restContextObj['assetGroupDesc'] = this.assetGrpType;

  if(validationErrors != null && validationErrors.length > 0){
    this.message = validationErrors;
    this.message.sort(this.messagesSortComparatorBasedOnTime);
  }
else{
  console.log('saveData: '  + JSON.stringify(restContextObj));
  if(securityUpdateArr.length > 0){   
   this.SpinnerService.show();
   this.bkrService.saveSecurityPrices(restContextObj).subscribe(response => {
     console.log('response ' + JSON.stringify(response));
     if(response.errorCount == 0){
       this.message = [new Message(null,'Successfully saved '+ securityUpdateArr.length+' '+this.assetGrpType+' security(s)',null,Utils.getCurrentTimeEST(),MessageTypes.SUCCESS)];
       this.SpinnerService.hide();
         this.onRefresh();
     }
  }, error=>{
    console.log("Error occurred while saving data | ",error);
    this.populateMsgListBox("Error occurred while saving data", MessageTypes.ERROR);
  });
  }
  else{
    this.message = [new Message(null,'No securities changed',null,Utils.getCurrentTimeEST(),MessageTypes.SUCCESS)];
  }
}
  }

  prepareSecurityUpdateObj(fiOrDrvObj){
    let securityUpdateObject = {}; 
    securityUpdateObject['key'] = 'updatedValues';
    let updatedValuesObject = {};  
      fiOrDrvObj.updatedValues.forEach((value, key) => {  
            updatedValuesObject[key] = value; 
                });
    securityUpdateObject['value'] = updatedValuesObject;
    securityUpdateObject['cusip'] = fiOrDrvObj.cusip;
    securityUpdateObject['sourceType'] = 'PRIMARY_BROKER';

    return securityUpdateObject;
  }

  getCusips(staleSecObjArr){
    let cusips = [];
    staleSecObjArr.map((Obj) => {
      cusips.push(Obj.cusip);
   });

   return JSON.stringify(cusips);
  }

  openDisclamer(){
    let dialogConfig = new MatDialogConfig();
    dialogConfig.width="70%";    
    //Get the Disclaimer text based on SourceId and assetCategory
    this.bkrService.getDisclaimerText(this.sourceId,this.assetGrpType).subscribe(response => {      
      let disclaimerText = response && response.text || " ";
      //console.log(`Disclaimer Text - ${disclaimerText}`);
      dialogConfig.data = {header : 'Disclaimer', content: disclaimerText};
      //Add custom class
      dialogConfig.panelClass = 'disclaimer-dialog-container';      
      //Open Disclaimer Dailog Popup window
      this.dialog.open(DisclaimerDialogComponent,dialogConfig);
    },
    error => {      
      console.log(`Error occured while fetching disclaimer text for the source id - ${this.sourceId} and asset category - ${this.assetGrpType} - ${JSON.stringify(error)}`);
    });    
  } 

  /**
   * Method to get Navigation Caption
   * @param assetType Assset Type
   * @returns Navigation Caption
   */
  getNavigationCaption(assetType: string): string {
    let title = assetType;
    switch(assetType){
      case 'HY':
        title = 'High Yield';
        break;
      case 'HG':
        title = 'High Grade';
        break;
      case 'CV':
        title = 'Convertible Bonds';
        break;
      case 'FOR':
        title = 'Foreign Securities';
        break;
      case 'PP':
        title = 'Private Placement';
        break;
      default:
        //console.log(`No Mapping found for asset type - ${assetType}`);   
    }
    return title;
  }


}

