import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'disclaimer-info-dialog',
  templateUrl: './disclaimer-dialog.component.html',
  styleUrls: ['./disclaimer-dialog.component.css']
})
export class DisclaimerDialogComponent implements OnInit {

  header : string;
  content : string;
  constructor(@Inject(MAT_DIALOG_DATA) public data) { 
    
    if(data){
      this.header = data.header;
      this.content = data.content;
    }
  }

  ngOnInit(): void {
  }

}
