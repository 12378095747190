import { PricingAttrType } from "./pricingattrtype";
import {DecimalPipe} from '@angular/common';
export class Utils{

   decimalPipe = new DecimalPipe('US'); 
    
getSourceSecIdAttrBySourceType(sourceType) {
    let attrType:PricingAttrType = null;
    switch (sourceType) {
    case 'PRIMARY_BROKER':
        attrType = PricingAttrType.PB_SEC_ID;
        break;
    case 'SECONDARY_BROKER':
        attrType = PricingAttrType.SB_SEC_ID;
        break;
    case 'PRIMARY_VENDOR':
        attrType = PricingAttrType.PV_SEC_ID;
        break;
    case 'SECONDARY_VENDOR':
        attrType = PricingAttrType.SV_SEC_ID;
        break;
    case 'AUDIT_BROKER':
        attrType = PricingAttrType.AB_SEC_ID;
        break;
    }
    return attrType;
}

getSourceUndlPriceAttrBySourceType(sourceType) {
    let attrType:PricingAttrType = null;
switch (sourceType) {
case 'PRIMARY_BROKER':
    attrType = PricingAttrType.PB_UNDL_SEC_PRICE;
    break;
case 'SECONDARY_BROKER':
    attrType = PricingAttrType.SB_UNDL_SEC_PRICE;
    break;
}
return attrType;
}

getSourceVolatilityAttrBySourceType(sourceType) {
    let attrType:PricingAttrType = null;
switch (sourceType) {
case 'PRIMARY_BROKER':
    attrType = PricingAttrType.PB_VOLATILITY;
    break;
case 'SECONDARY_BROKER':
    attrType = PricingAttrType.SB_VOLATILITY;
    break;
}
return attrType;
}

getSourceDeltaAttrBySourceType(sourceType) {
    let attrType:PricingAttrType = null;
switch (sourceType) {
case 'PRIMARY_BROKER':
    attrType = PricingAttrType.PB_DELTA;
    break;
case 'SECONDARY_BROKER':
    attrType = PricingAttrType.SB_DELTA;
    break;
}
return attrType;
}

getSourceMarketValueBySourceType(sourceType) {
    let attrType:PricingAttrType = null;
switch (sourceType) {
case 'PRIMARY_BROKER':
    attrType = PricingAttrType.PB_MKT_VAL;
    break;
case 'SECONDARY_BROKER':
    attrType = PricingAttrType.SB_MKT_VAL;
    break;
case 'AUDIT_BROKER':
    attrType = PricingAttrType.AB_MKT_VAL;
    break;
}
return attrType;
}

getSourcePriceAttrBySourceType(sourceType) {
    let attrType:PricingAttrType = null;
switch (sourceType) {
case 'PRIMARY_BROKER':
    attrType = PricingAttrType.PB_PRICE;
    break;
case 'SECONDARY_BROKER':
    attrType = PricingAttrType.SB_PRICE;
    break;
case 'PRIMARY_VENDOR':
    attrType = PricingAttrType.PV_PRICE;
    break;
case 'SECONDARY_VENDOR':
    attrType = PricingAttrType.SV_PRICE;
    break;
case 'AUDIT_BROKER':
    attrType = PricingAttrType.AB_PRICE;
    break;
}
return attrType;
}

getSourceStatusAttrBySourceType(sourceType) {
    let attrType:PricingAttrType = null;
switch (sourceType) {
case 'PRIMARY_BROKER':
    attrType = PricingAttrType.PB_STATUS;
    break;
case 'SECONDARY_BROKER':
    attrType = PricingAttrType.SB_STATUS;
    break;
case 'PRIMARY_VENDOR':
    attrType = PricingAttrType.PV_STATUS;
    break;
case 'SECONDARY_VENDOR':
    attrType = PricingAttrType.SV_STATUS;
    break;
case 'AUDIT_BROKER':
    attrType = PricingAttrType.AB_STATUS;
    break;
}
return attrType;
}

getBrokerSourceTypeDRV(sourceId,brokerSrcId,secBrokerSrcId) {
    let brokerSourceType = null;
    if (brokerSrcId != null && brokerSrcId == sourceId) {
        brokerSourceType = 'PRIMARY_BROKER';
    } else if (secBrokerSrcId != null && secBrokerSrcId == sourceId) {
        brokerSourceType = 'SECONDARY_BROKER';
    }
    return brokerSourceType;
}

getBrokerSourceTypeFI(sourceId,brokerSrcId,auditBrokerSrcId) {
    let brokerSourceType = null;
    if(brokerSrcId != null && brokerSrcId == sourceId){
        brokerSourceType = 'PRIMARY_BROKER';
    } else if (auditBrokerSrcId != null && auditBrokerSrcId == sourceId){
        brokerSourceType = 'AUDIT_BROKER';
    } 
    
    return brokerSourceType;
}

getFormatedColumn(columnName){
    console.log('columnName ' + columnName);
    //let decimalPipe: DecimalPipe = null;
    var fomattedNumber = null;
    columnName = columnName + '';
    let isValid = false;
    //var re = new RegExp("(\\-\\d{1,13})(\\.\\d+)|(\\-\\d{0,13}\\.\\d+)|(\\d{1,13})|(\\d{0,13}\\.\\d+)|(\\-\\d{0,13})");
    //var re = /^ (([0-9]{1,13}\.[0-9]+) | ([0-9]{0,13}\.[0-9]+) | ([0-9]{1,13}) | ([0-9]{0,13})) $/;
    if(/^\-[0-9]{1,13}\.[0-9]+$/.test(columnName)){
        isValid = true;
    }
    if(/^\-[0-9]{0,13}\.[0-9]+$/.test(columnName)){
        isValid = true;
    }
    if(/^[0-9]{0,13}\.[0-9]+$/.test(columnName)){
        isValid = true;
    }
    if(/^[0-9]{1,13}$/.test(columnName)){
        isValid = true;
    }
    if(/^\-[0-9]{0,13}$/.test(columnName)){
        isValid = true;
    }
    //|(\d{1,13})(\.\d+)|(\d{0,13}\.\d+)$/;
    console.log('regex' + isValid);
  if(isValid){
  var   scaleIndex = columnName.indexOf(".");
  if (scaleIndex !== -1) {
    var totalFractionNumbers = columnName.substring(
      scaleIndex + 1, columnName.length).length;
    //   if (totalFractionNumbers <= 8 )//Utils.MOPS_DECIMAL_SCALE)
    //   {
    //     fomattedNumber = columnName;
    //   }else {
    //     console.log('columnName after ' + columnName);
    //     fomattedNumber =  decimalPipe.transform(columnName, '0.1-12');
    //   }
    console.log('totalFractionNumbers ' + totalFractionNumbers);
    if (totalFractionNumbers <= 8 )//Utils.MOPS_DECIMAL_SCALE)
    {
      fomattedNumber = columnName;
    }else {
        console.log('formatted' + columnName);
      fomattedNumber = Number.parseFloat(columnName).toFixed(2); 
    }
  } else {
    fomattedNumber = columnName;
  }
  }
  console.log('fomattedNumber ' + fomattedNumber);
  return fomattedNumber;
   }

   
 static getCurrentTimeEST(){
   return new Date().toLocaleString('en-US', { timeZone: 'America/New_York' });
 }

 static isValidFile(file:File){
     const supportedTypes = ['txt','csv','xlsx','xlx'];

     if(file){
        let extension = file.name.substring(file.name.lastIndexOf('.')+1)
        return supportedTypes.includes(extension);
     }

     return false;

 }

 getMessageObj(validationsErrors){
    let cusipVsMessage = new Map<String, Object>();
    validationsErrors.map((messageObj) => {
      cusipVsMessage.set(messageObj.putnamId,messageObj);
   });
   return cusipVsMessage;
  }
}