import { Component, OnInit,Input } from '@angular/core';
import { PricingAttrType } from '../../util/pricingattrtype';
import { Utils } from '../../util/utils';
import {Message, MessageTypes} from './../../models/message';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-broker-drv-datatable',
  templateUrl: './broker-drv-datatable.component.html',
  styleUrls: ['./broker-drv-datatable.component.css']
})
export class BrokerDRVDatatableComponent implements OnInit {

  status:string;
  cusip:string;
  brokerSecId:string;
  brokerSecIdOnLoad:string;
  securityName:string;
  dealNotional:number;
  mktValue:string;
  price:number;
  priceOnLoad:number;
  yesterdayUnitizedPrice:string;
  pricingCurrency:string;
  underlyingSecurityPrice:number;
  underlyingSecurityPriceOnLoad:number;
  volatility:number;
  volatilityOnLoad:number;
  delta:number;
  deltaOnLoad:number;
  fxRate:number;
  assetCurrency:string;
  securityType:string;
  maturityDate:string;
  dailyMonthly:string;
  comments:string;
  updatedValues = new Map<PricingAttrType, Object>();
  updateFlag:boolean = false;
  utils = new Utils();
  validationsErrors = [];

  mktValueFl:boolean;
  accruedInt:number;
  validSecType:string;
  displaySourceComment:boolean;
  brokerSecurityId:string;
  brokerMktValue:string;
  staleSecFlag:boolean = false;
  swapTypeCode:string;
  prevDayPriceDetail:object;

  bkrSecIdFlag:boolean = false;
  bkrPriceFlag:boolean = false;
  undlSecPriceFlag:boolean = false;
  bkrVolatilityFlag:boolean = false;
  bkrDeltaFlag:boolean = false; 
  
  secIdAttr : string = "secIdAttr";
	priceIdAttr : string = "priceIdAttr";
	mktId : string = "mktId";
	volAttr : string = "volAttr";
	delAttr : string = "delAttr";
	unpriceIdAttr : string = "unpriceIdAttr";

  @Input() DRVDataArray:any[];
  @Input() DRVAllData:any[];

  constructor() { }

  ngOnInit(): void {
  }

  syncBVdata(columnName,DRVObj,columnVal){
    columnVal = (typeof columnVal === 'string' ? columnVal.trim() : columnVal);
    let index = this.DRVAllData.indexOf(DRVObj);
    
    if(columnName === 'BROKER_SEC_ID'){
      if(DRVObj.brokerSecId != columnVal){
        let pricingattrtype = this.utils.getSourceSecIdAttrBySourceType('PRIMARY_BROKER');
      if(DRVObj.brokerSecIdOnLoad == columnVal){
        DRVObj.brokerSecId = DRVObj.brokerSecIdOnLoad;
        if(DRVObj.updatedValues.has(PricingAttrType[pricingattrtype])){
          DRVObj.updatedValues.delete(PricingAttrType[pricingattrtype]);
        }
        DRVObj.bkrSecIdFlag = false;
      }
      else{
     DRVObj.brokerSecId = columnVal;
    DRVObj.updatedValues.set(PricingAttrType[pricingattrtype],DRVObj.brokerSecId);
    DRVObj.bkrSecIdFlag = true;
      }
  
    }
    }
    if(columnName === 'BROKER_PRICE'){     
      this.removeValidationError(DRVObj);
      let zeroFlag = (DRVObj.price === 0 && columnVal == '');
      if( zeroFlag || (DRVObj.price != columnVal)){
        let srcMktVal = this.utils.getSourceMarketValueBySourceType('PRIMARY_BROKER');
        let pricingattrtype = this.utils.getSourcePriceAttrBySourceType('PRIMARY_BROKER');        
        if(!zeroFlag && DRVObj.priceOnLoad == columnVal){
          DRVObj.price = DRVObj.priceOnLoad;
          if(DRVObj.updatedValues.has(PricingAttrType[pricingattrtype])){
            DRVObj.updatedValues.delete(PricingAttrType[pricingattrtype]);
          }
          if(DRVObj.updatedValues.has(PricingAttrType[srcMktVal])){
            DRVObj.updatedValues.delete(PricingAttrType[srcMktVal]);
          }
          DRVObj.bkrPriceFlag = false;
        }
        else{

      if(columnVal == ''){
        DRVObj.price = null;
        if (DRVObj.mktValueFl) {
        DRVObj.updatedValues.set(PricingAttrType[srcMktVal],DRVObj.price);
        }
        DRVObj.updatedValues.set(PricingAttrType[pricingattrtype],DRVObj.price);
        DRVObj.bkrPriceFlag = true;
      } 
      else{   
      let unitizePrice = null;
      let formattedNum = this.utils.getFormatedColumn(columnVal);
      if(formattedNum != null){
      DRVObj.price = parseFloat(formattedNum);
      if (DRVObj.mktValueFl) {
        unitizePrice = this.calculateUnitizedPrice(DRVObj.dealNotional,DRVObj.accruedInt,DRVObj.price);
        DRVObj.updatedValues.set(PricingAttrType[srcMktVal],DRVObj.price);
      }
      if(unitizePrice == null){
        unitizePrice = DRVObj.price;
      }      
      DRVObj.updatedValues.set(PricingAttrType[pricingattrtype],parseFloat(unitizePrice));      
      if(DRVObj.validSecType == 'TSWP'){
        DRVObj.staleSecFlag = this.isStaleSec(DRVObj,parseFloat(unitizePrice));
      }
      DRVObj.bkrPriceFlag = true;
    }
    else{
      DRVObj.validationsErrors.push(new Message(DRVObj.cusip  ,'Invalid format of Broker Price.Required format: -9999999999999.99999999.',null,Utils.getCurrentTimeEST(),MessageTypes.ERROR));
    }
  }
  }
      }
    }
      if(columnName === 'BROKER_UNDL_SEC_PRICE'){
        this.removeValidationError(DRVObj);
        let zeroFlag = (DRVObj.underlyingSecurityPrice === 0 && columnVal == '');
        if(zeroFlag || DRVObj.underlyingSecurityPrice != columnVal){
          let pricingattrtype = this.utils.getSourceUndlPriceAttrBySourceType('PRIMARY_BROKER');
          if(!zeroFlag && DRVObj.underlyingSecurityPriceOnLoad == columnVal){
            DRVObj.underlyingSecurityPrice = DRVObj.underlyingSecurityPriceOnLoad;
            if(DRVObj.updatedValues.has(PricingAttrType[pricingattrtype])){
              DRVObj.updatedValues.delete(PricingAttrType[pricingattrtype]);
            }
            DRVObj.undlSecPriceFlag = false;
          }
          else{
            if(columnVal == ''){
              DRVObj.price = null;
              DRVObj.updatedValues.set(PricingAttrType[pricingattrtype],DRVObj.price);
              DRVObj.undlSecPriceFlag = true;
            } 
            else{
          let formattedNum = this.utils.getFormatedColumn(columnVal);
          if(formattedNum != null){
        DRVObj.underlyingSecurityPrice = parseFloat(formattedNum);        
      
        DRVObj.updatedValues.set(PricingAttrType[pricingattrtype],DRVObj.underlyingSecurityPrice);
        DRVObj.undlSecPriceFlag = true;
        
        }
        else{
          DRVObj.validationsErrors.push(new Message(DRVObj.cusip  ,'Invalid format of Underlying Price.Required format: -9999999999999.99999999.',null,Utils.getCurrentTimeEST(),MessageTypes.ERROR));
        }
      }
      }
      }
      }
        if(columnName === 'BROKER_VOLATILITY'){
          this.removeValidationError(DRVObj);
          let zeroFlag = (DRVObj.volatility === 0 && columnVal == '');
          if(zeroFlag || DRVObj.volatility != columnVal){
            let pricingattrtype = this.utils.getSourceVolatilityAttrBySourceType('PRIMARY_BROKER');
            if(!zeroFlag && DRVObj.volatilityOnLoad == columnVal){
              DRVObj.volatility = DRVObj.volatilityOnLoad;
              if(DRVObj.updatedValues.has(PricingAttrType[pricingattrtype])){
                DRVObj.updatedValues.delete(PricingAttrType[pricingattrtype]);
              }
              DRVObj.bkrVolatilityFlag = false;
            }
            else{
              if(columnVal == ''){
                DRVObj.price = null;
                DRVObj.updatedValues.set(PricingAttrType[pricingattrtype],DRVObj.price);
                DRVObj.bkrVolatilityFlag = true;
              } 
              else{
            let formattedNum = this.utils.getFormatedColumn(columnVal);
            if(formattedNum != null){
          DRVObj.volatility = parseFloat(formattedNum);;
          //Commenting source type since its fixed for now change according to requirement
          //let sourceType = this.utils.getBrokerSourceTypeDRV('srcId','srcId',null);
          DRVObj.updatedValues.set(PricingAttrType[pricingattrtype],DRVObj.volatility);
          DRVObj.bkrVolatilityFlag = true;
          }
          else{
            DRVObj.validationsErrors.push(new Message(DRVObj.cusip  ,'Invalid format of Volatility.Required format: -9999999999999.99999999.',null,Utils.getCurrentTimeEST(),MessageTypes.ERROR));
          }
        }
        }
        }
        }
          if(columnName === 'BROKER_DELTA'){
            this.removeValidationError(DRVObj);
            let zeroFlag = (DRVObj.delta === 0 && columnVal == '');
            if(zeroFlag || DRVObj.delta != columnVal){
              let pricingattrtype = this.utils.getSourceDeltaAttrBySourceType('PRIMARY_BROKER');
              if(!zeroFlag && DRVObj.deltaOnLoad == columnVal){
                DRVObj.delta = DRVObj.deltaOnLoad;
                if(DRVObj.updatedValues.has(PricingAttrType[pricingattrtype])){
                  DRVObj.updatedValues.delete(PricingAttrType[pricingattrtype]);
                }
                DRVObj.bkrDeltaFlag = false;
              }
              else{
                if(columnVal == ''){
                  DRVObj.price = null;
                  DRVObj.updatedValues.set(PricingAttrType[pricingattrtype],DRVObj.price);
                  DRVObj.bkrDeltaFlag = true;
                } 
                else{
              let formattedNum = this.utils.getFormatedColumn(columnVal);
              if(formattedNum != null){
            DRVObj.delta = parseFloat(formattedNum);;
            //Commenting source type since its fixed for now change according to requirement
           //let sourceType = this.utils.getBrokerSourceTypeDRV('srcId','srcId',null);
            DRVObj.updatedValues.set(PricingAttrType[pricingattrtype],DRVObj.delta);
            DRVObj.bkrDeltaFlag = true;
            
            }
            else{
              DRVObj.validationsErrors.push(new Message(DRVObj.cusip  ,'Invalid format of Delta.Required format: -9999999999999.99999999.',null,Utils.getCurrentTimeEST(),MessageTypes.ERROR));
            }
          }
          }
          }
            
      }

      if(DRVObj.bkrSecIdFlag || DRVObj.bkrPriceFlag || DRVObj.undlSecPriceFlag || DRVObj.bkrVolatilityFlag || DRVObj.bkrDeltaFlag){
        DRVObj.updateFlag = true;
      }
      else{
        DRVObj.updateFlag = false;
      }
      this.DRVAllData[index] = DRVObj;
  }

  removeValidationError(DRVObj){    
    if(DRVObj.validationsErrors.length > 0){
      let msgObjindex = DRVObj.validationsErrors.indexOf(this.utils.getMessageObj(DRVObj.validationsErrors).get(DRVObj.cusip));
      if (msgObjindex > -1) {
        DRVObj.validationsErrors.splice(msgObjindex, 1);
      }
    }
  }

calculateUnitizedPrice(notional,accruedInt,marketValue) {
 console.log('accruedInt ' + accruedInt + 'notional '  + notional  + 'marketValue ' + marketValue)
  var unitizePrice = null;
  if (notional != null && accruedInt != null && marketValue != null) {
          unitizePrice =  Math.round(((marketValue - accruedInt) + (notional) / notional)) - 1;
  }
  return unitizePrice;
  }

  isStaleSec(DRVObj,currentBrokerPrice){
    console.log('currentBrokerPrice: ' + currentBrokerPrice);
    if (!(DRVObj.swapTypeCode == 'J' || DRVObj.swapTypeCode == 'K' || DRVObj.swapTypeCode == 'D')) {
      if (DRVObj.prevDayPriceDetail !=null) {
            let prevDayBrokerPrice:number =  DRVObj.prevDayPriceDetail.priBrokerPrice; 
            if (currentBrokerPrice != null && prevDayBrokerPrice != null && currentBrokerPrice == prevDayBrokerPrice) {
              console.log('prevDayBrokerPrice: ' + prevDayBrokerPrice);      
              return true;
          }
      }

    }
    return false;
  }

  isValidOptionPST(securityType){
    var PSTCodes: Array<string> = ['CBOP','CUOP','EIFO','EIOP','ETOP','IRFO','TBFO','TBOP','EOIO','EQOP','EXOP','EOFO'];
    //'CMBX',
    if(PSTCodes.indexOf(securityType) > -1){
        return true;
    }
   return false;
  }

  scrollHeader() {    
    let mainTbl = document.getElementById("tableDiv");    
    document.getElementById("header").scrollLeft = mainTbl.scrollLeft; 
  }

  isEditable(brokerStatus){
    switch (brokerStatus) {
      
      case 'Pending':
        return false;
      case 'Priced':
          return true;
      case 'Rejected':
        return false;
      case 'Accepted':
        return true;
  }

}

isDisplaySourceComment(item,sourceType) {
  let middleOfficeComments = null;
  switch (sourceType) {
  case 'PRIMARY_BROKER':
    middleOfficeComments = item.middleOffPBComments;
    break;
  case 'SECONDARY_BROKER':
    middleOfficeComments = item.middleOffSBComments;
    break;
  case 'PRIMARY_VENDOR':
    middleOfficeComments = item.middleOffPVComments;
    break;
  case 'SECONDARY_VENDOR':
    middleOfficeComments = item.middleOffSVComments;
    break;
  }
  return middleOfficeComments != null;
  }
  
  getAttribute(item: any,attr:string) {
     let obj = null;
     let productAttr = null;
     if(attr == 'secIdAttr'){
       productAttr = this.utils.getSourceSecIdAttrBySourceType('PRIMARY_BROKER');
     }else if(attr == 'volAttr'){
       productAttr = this.utils.getSourceVolatilityAttrBySourceType('PRIMARY_BROKER');
     }else if(attr == 'delAttr'){
       productAttr = this.utils.getSourceDeltaAttrBySourceType('PRIMARY_BROKER');
     }else if(attr == 'unpriceIdAttr'){
       productAttr = this.utils.getSourceUndlPriceAttrBySourceType('PRIMARY_BROKER');
     }else if(attr == 'priceAttr'){
       productAttr = this.utils.getSourcePriceAttrBySourceType('PRIMARY_BROKER');
     }else if(attr == 'mktId'){
       productAttr = this.utils.getSourcePriceAttrBySourceType('PRIMARY_BROKER');      
     }    
       switch (PricingAttrType[productAttr]) {
        case "PB_SEC_ID":
           obj = item.brokerSecurityId;
           break;
        case "PB_VOLATILITY":
            obj = item.brokerVolatility;
            break;
        case "PB_DELTA":
           obj = item.brokerDelta;
           break;
        case "PB_UNDL_SEC_PRICE":
            obj = item.brokerUndlSecPrc;
            break;
        case "PB_PRICE":
           switch (item.brokerPriceStatus) {
           case "R":
             obj = null;
             break;
           default:
             obj = item.brokerPrice;
             break;
           }
           break;
        case "PB_MKT_VAL":
           obj = item.brokerMktValue;
           break;
        //commenting other cases for now since source type is fixed
        //  case "AB_PRICE":
        //    switch (item.abPrcStatus) {
        //    case "R":
        //      obj = null;
        //      break;
        //    default:
        //      obj = item.auditBrokerPrice;
        //      break;
        //    }
        //    break;
        //  case "AB_STATUS":
        //    obj = item.auditBrokerStatus;
        //    break;
        //  case "AB_SEC_ID":
        //    obj = item.auditBrokerSecurityId;
        //    break;
        //  case "PB_STATUS":
        //    obj = item.brokerPriceStatus;
        //    break;
        //  case "PB_COMMENTS":
        //    obj = item.brokerPriceComments;
        //    break;
        //  case "PV_STATUS":
        //    obj = item.vendorPricingStatusType;
        //    break;
        //  case "PV_PRICE":
          
        //    switch (item.vendorPriceStatus) {
        //    case "R":
        //      obj = null;
        //      break;
        //    default:
        //      obj = item.vendorPrice;
        //      break;
        //    }
        //    break;
        //  case "PV_COMMENTS":
        //    obj = item.vendorPriceComments;
        //    break;
        //  case "PV_SEC_ID":
        //    obj = item.vendorSecurityId;
        //    break;
        //  case "SB_STATUS":
        //    obj = item.secBrokerPricingStatusType;
        //    break;
        //  case "SB_PRICE":
          
        //    switch (item.secBrokerPriceStatus) {
        //    case "R":
        //      obj = null;
   
   
        //      break;
        //    default:
        //      obj = item.secBrokerPrice;
        //      break;
        //    }
        //    break;
        //  case "SB_MKT_VAL":
        //    obj = item.secBrokerMktValue
        //    break;
        //  case "SB_COMMENTS":
        //    obj = item.secBrokerPriceComments;
        //    break;
        //  case "SB_SEC_ID":
        //    obj = item.secBrokerSecurityId;
        //    break;
        //  case "SB_UNDL_SEC_PRICE":
        //    obj = item.secBrokerUndlSecPrc;
        //    break;
        //  case "SB_VOLATILITY":
        //    obj = item.secBrokerVolatility;
        //    break;
        //  case "SB_DELTA":
        //    obj = item.secBrokerDelta;
        //    break;
        //  case "SV_STATUS":
        //    obj = item.secVndPricingStatusType;
        //    break;
        //  case "SV_PRICE":
            
        //    switch (item.secVndPriceStatus) {
        //    case "R":
        //      obj = null;
        //      break;
        //    default:
        //      obj = item.secVndrPrice;
        //      break;
        //    }
        //    break;
        //  case "SV_COMMENTS":
        //    obj = item.secVndPriceComments;
        //    break;
        //  case "SV_SEC_ID":
        //    obj = item.secVndSecurityId;
        //    break;
        //  case "MO_PB_COMMENTS":
        //    obj = item.middleOffPBComments;
        //    break;
        //  case "MO_PV_COMMENTS":
        //    obj = item.middleOffPVComments;
        //    break;
        //  case "MO_SB_COMMENTS":
        //    obj = item.middleOffSBComments;
        //    break;
        //  case "MO_SV_COMMENTS":
        //    obj = item.middleOffSVComments;
        //    break;
        //  case "DV01":
        //    obj = item.dV01;
        //    break;
        //  case "FUNDING_LEVEL":
        //    obj = item.fundingLevel;
        //    break;
        //  case "PAR_SPREAD":
        //    obj = item.parSpread;
        //    break;
        //  case "EX_OPT_PRICE":
        //    obj = item.exoticOptionValue;
        //    break;
        //  case "THEO_PRICE":
        //    obj = item.theoreticalPrice;
        //    break;
        //  case "THRESHOLD":
        //    obj = item.threshold;
        //    break;
        //  case "ACCNT_PRC":
        //    obj = item.prevDayAccountingPrice;
        //    break;
       }
       if (obj == null) {
         obj = "";
       }   
      //  else if (typeof(obj) === 'number' ) {
      //    switch (item.cusip) {
      //    case "FUNDING_LEVEL":
      //    case "THRESHOLD":
      //    case "PAR_SPREAD":
      //      obj = obj;// "obj | 'number':'3.1-5'"; //obj.formatNumber();
      //      if(obj.includes("-")&& obj.length >=13)
      //    {
      //      obj= obj.substring(0, 12);
      //    }
      //      break;
      //    default:
      //      obj = obj;//{obj | number : 2} ; //getDoubleStr((BigDecimal) object, PRC_DOUBLE_FORMAT);''
      //    }
      //  }  
   
       return(obj);
       
   }

   getTableHeightStyle(): string {     
     return 'calc(100vh - 510px)';
   }

   getRowDataItemClass(baseClass, status): string {     
     return (status === 'Rejected'? baseClass + '-highlight' : baseClass);
   }

   numberCheck(e, comp, type, precision, scale) {
    var flag = true;
    var keyCode = e.keyCode;
    
    // Enables delete, backspace & arrows keys (left, right, up, down) in firefox, 
    // backspace is 8 others are 0. 
    if(e.which == 0 || e.which == 8) {
      return true;
    }
     
    var str = comp;
    var maxLength = -1;
    if (document.all) {keyCode = e.keyCode;} 
    else {keyCode = e.which;}
  //	alert(keyCode);
    
    
    if(type == 'n') {maxLength = precision;}
    else if(type == 'nf') {maxLength = precision + scale + 1;} // 1 for dot (.)
    else if(type == 'nnf') {maxLength = precision + scale + 2;} // 1 for dot (.), 1 for minus sign (-)
  
    if (str.length == maxLength) {return false;}
  
    // Only number allowed	
    if(keyCode > 47 && keyCode < 58){flag = true;}
    else{flag = false;}
  
    // Dot
    if ((type == "nf" || type == "nnf") && str.indexOf(".") == -1 && keyCode == 46) {
      flag = true;
    }
  
    // Negative number (minus sign)
    if (type == "nnf" && str.indexOf("-") == -1 && str.length == 0 && keyCode == 45) {
      flag = true;
    }
    
    return flag;
  }
}
  

